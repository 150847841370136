<template>
  <div
    class="relative h-full w-full"
    :class="{ 'cursor-pointer': !loading, 'cursor-wait': loading }"
    @click="onClick"
    @dragover.prevent="dropZoneHighlighted = true"
    @dragleave="dropZoneHighlighted = false"
    @mouseenter="dropZoneHighlighted = true"
    @mouseleave="dropZoneHighlighted = false"
    @drop.prevent="onDrop"
  >
    <div
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center bg-white/50"
    >
      Uploading...
    </div>
    <input
      v-show="false"
      :id="id"
      ref="fileInput"
      :accept="accept"
      type="file"
      @change="onFileInputChange"
    />

    <div
      class="flex h-full w-full flex-col items-center justify-center rounded-md border border-dashed text-gray-600 transition"
      :class="dropZoneHighlighted ? 'border-gray-700' : 'border-gray-300'"
    >
      <UploadSimpleRegular
        class="mb-2 h-6 w-6"
        :class="{ invisible: loading }"
      />
      <div
        class="tracking-snug mb-2 px-3 text-center font-semibold"
        :class="{ invisible: loading }"
      >
        <slot name="title"> Click or drag file to this area to upload </slot>
      </div>
      <div
        class="px-3 text-center text-sm text-gray-400 empty:hidden"
        :class="{ invisible: loading }"
      >
        <slot name="description" />
      </div>
    </div>
  </div>
</template>

<script>
import UploadSimpleRegular from '@/phosphoricons/UploadSimpleRegular.vue';

export default {
  components: { UploadSimpleRegular },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },
  emits: ['file'],
  data() {
    return {
      dropZoneHighlighted: false,
    };
  },
  computed: {
    disabled() {
      return this.loading;
    },
  },
  methods: {
    onFileInputChange(e) {
      if (this.disabled) {
        return;
      }
      this.handleFile(e.target.files[0]);
      this.$refs.fileInput.value = null;
    },
    onDrop(e) {
      if (this.disabled) {
        return;
      }
      this.handleFile(e.dataTransfer.files[0]);
    },
    onClick() {
      if (this.disabled) {
        return;
      }
      this.$refs.fileInput.click();
    },
    handleFile(file) {
      this.$emit('file', file);
    },
  },
};
</script>
