<template>
  <DashboardWrapper title="Invoices">
    <ACard class="mb-4">
      <div v-if="invoices.length === 0" class="italic text-gray-500">
        There are no invoices yet...
      </div>
      <div v-else class="mb-2 italic text-gray-500">
        Need to change information on an invoice?
        <Link href="/support" class="underline">Contact us.</Link>
      </div>
      <div>
        <div
          v-for="(invoice, invoiceIndex) in invoices"
          :key="invoiceIndex"
          class="mb-4 rounded border px-4 py-4 shadow-lg"
        >
          <div><strong>Date:</strong> {{ formatDate(invoice.createdAt) }}</div>
          <div>
            <strong>Amount:</strong> {{ invoice.currencyCode }}
            {{ invoice.amount }}
          </div>
          <div class="mt-2 flex">
            <a
              v-if="invoice.invoiceUrl"
              :href="invoice.invoiceUrl"
              target="_blank"
              class="btn btn-primary btn-sm"
              >Download invoice</a
            >
            <div v-else class="text-sm text-gray-500">
              <div>Please wait, invoice will be available soon.</div>
              <div>If it doesn't appear after 48 hours, please contact us.</div>
            </div>
          </div>
        </div>
      </div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import { DateTime } from 'luxon';
import { Link } from '@inertiajs/vue3';

export default {
  components: { Link, ACard, DashboardWrapper },
  props: {
    invoices: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL);
    },
  },
};
</script>
