<template>
  <DashboardWrapper
    :title="success ? 'Thanks for subscribing! 🎉' : 'Current Subscription'"
  >
    <ACard>
      <div class="flex flex-col">
        <template v-if="details.isCancelled">
          <div>Your subscription has been cancelled.</div>
          <div>If you wish to renew, please message us.</div>
        </template>

        <template v-else>
          <template v-if="details.actionUrl">
            <div
              class="rounded-lg border border-red-500 bg-red-50 p-4 text-red-600"
            >
              <div>
                <strong>Action Required:</strong> we were unable to charge your
                card, complete the payment by clicking the button below.
              </div>
              <div class="flex">
                <a
                  :href="details.actionUrl"
                  target="_blank"
                  class="mt-2 block rounded-lg bg-red-600 px-3 py-1 text-white transition hover:bg-red-700"
                  >Complete Payment</a
                >
              </div>
            </div>
          </template>
          <template v-else-if="details.nextPayment">
            <div>
              <strong>Next payment amount: </strong>
              {{ details.nextPayment.currency }}
              {{ details.nextPayment.amount }}
            </div>
            <div>
              <strong>Next payment date: </strong>
              {{ nextPaymentDateFormatted }}
            </div>
          </template>
        </template>
        <div v-if="details.manageUrl" class="mt-4">
          <AButton @click="manageSubscription">Manage Subscription</AButton>
        </div>
        <div
          v-if="$page.props._restaurant.countryCode === 'AE'"
          class="mt-2 flex"
        >
          <Link href="/billing/invoices" class="btn btn-primary btn-sm"
            >View invoices</Link
          >
        </div>
      </div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import AButton from '@app/Shared/AButton.vue';
import { DateTime } from 'luxon';
import confetti from 'canvas-confetti';
import { Link } from '@inertiajs/vue3';

export default {
  components: {
    AButton,
    ACard,
    DashboardWrapper,
    Link,
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    nextPaymentDateFormatted() {
      return DateTime.fromISO(this.details.nextPayment.date).toLocaleString(
        DateTime.DATETIME_FULL,
      );
    },
  },
  mounted() {
    if (this.success) {
      window.fbq && window.fbq('track', 'Subscribe');

      window.gtag &&
        window.gtag('event', 'conversion', {
          send_to: 'AW-10942188799/XpWECK_i46EYEP-Z0uEo',
        });

      this.celebrate();
    }
  },
  methods: {
    manageSubscription() {
      window.open(this.details['manageUrl'], '_blank');
    },
    celebrate() {
      const duration = 5 * 1000;
      const animationEnd = Date.now() + duration;
      const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }

      const interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          }),
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          }),
        );
      }, 250);
    },
  },
};
</script>
