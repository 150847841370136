<template>
  <DashboardWrapper title="Restaurant settings">
    <RestaurantSettingsTabs />

    <ACard class="mb-4">
      <form class="space-y-4" @submit.prevent="submit">
        <AInput
          v-model="form.name"
          label="Restaurant name"
          placeholder="Enter the name of your restaurant..."
          :error="form.errors.name"
        />

        <div>
          <AMultiselect
            v-model="form.languages"
            label="Menu languages"
            :options="languages"
            :value-resolver="(language) => language.code"
            :text-resolver="
              (language) => `${language.name} (${language.nativeName})`
            "
            :error="form.errors.languages"
          />
        </div>

        <ASelect
          v-model="form.currencyCode"
          label="Menu currency"
          :options="currencies"
          :value-resolver="(currency) => currency.code"
          :text-resolver="(currency) => currency.code"
          :error="form.errors.currencyCode"
        />

        <div
          class="sticky bottom-0 z-10 flex w-full items-center bg-white py-3"
        >
          <AButton type="submit" class="mt-3" :loading="form.processing">
            Save
          </AButton>
          <div v-if="form.isDirty" class="ml-3 text-gray-400">
            (has unsaved changes)
          </div>
        </div>
      </form>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import AInput from '@app/Shared/AInput.vue';
import { useForm } from '@inertiajs/vue3';
import ASelect from '@app/Shared/ASelect.vue';
import AButton from '@app/Shared/AButton.vue';
import ACard from '@app/Shared/ACard.vue';
import { getLanguageByCode, getLanguages } from '@/utils/language.js';
import sortBy from 'lodash/sortBy';
import AMultiselect from '@app/Shared/AMultiselect.vue';
import RestaurantSettingsTabs from '@app/Shared/Tabs/RestaurantSettingsTabs.vue';

export default {
  components: {
    AMultiselect,
    ACard,
    AButton,
    ASelect,
    AInput,
    DashboardWrapper,
    RestaurantSettingsTabs,
  },
  props: {
    global: {
      type: Object,
      required: true,
    },
    restaurant: {
      type: Object,
      required: true,
    },
    currencies: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        name: this.restaurant.name ?? '',
        languages: this.restaurant.languages,
        currencyCode: this.restaurant.currencyCode,
      }),
    };
  },
  computed: {
    languages() {
      return sortBy(getLanguages(), 'name');
    },
  },
  methods: {
    submit() {
      this.form.put('/restaurant', {
        preserveScroll: true,
        onError: () => {
          this.$toast.error('Please fix all highlighted fields');
        },
        onSuccess: () => {
          this.$toast.success('Saved');
        },
      });
    },
    getLanguageByCode,
  },
};
</script>
