<template>
  <DashboardWrapper :title="`Subscription for ${$page.props._restaurant.name}`">
    <ACard>
      <div
        v-if="trialEndsAt || subscriptionCancelledAt"
        class="mb-4 rounded-lg px-6 py-4"
        :class="[
          isExpiredTrial || subscriptionCancelledAt
            ? 'bg-red-500 text-white'
            : 'border border-yellow-100 bg-yellow-50 text-yellow-900',
        ]"
      >
        <div class="text-lg font-semibold">
          <template v-if="subscriptionCancelledAt">
            Your subscription for {{ $page.props._restaurant.name }} was
            cancelled {{ subscriptionCancelledAt.toRelative() }}.
          </template>
          <template v-else-if="isExpiredTrial">
            Your free trial for {{ $page.props._restaurant.name }} has ended
            {{ trialEndsAt.toRelative() }}.
          </template>
          <template v-else>
            Your free trial for {{ $page.props._restaurant.name }} ends
            {{ trialEndsAt.toRelative() }}.
          </template>
        </div>
        <div class="mt-1 text-sm">
          <template v-if="isExpiredTrial || subscriptionCancelledAt">
            <strong>People can't view your menu anymore.</strong>
            Once you subscribe below, your menu will become active immediately.
          </template>
          <template v-else>
            To keep your menu active, subscribe below before your free trial
            ends.
          </template>
        </div>
      </div>

      <div class="text-lg font-bold">Choose your country</div>

      <div class="mt-1">
        <div class="flex">
          <ASelect
            v-model="countryCode"
            :options="countries"
            :value-resolver="(option) => option.code"
            :text-resolver="(option) => option.name"
          />
        </div>

        <div class="mt-1 text-sm text-gray-500">
          This country will appear on your invoice.
        </div>
      </div>

      <div class="mt-6 text-lg font-bold">Choose your plan</div>

      <div class="mt-1">
        <div v-if="filteredPlans.length === 0" class="text-gray-500">
          There are no available plans for your restaurant in this country.
          <Link href="/support" class="underline">Contact us.</Link>
        </div>
        <div v-else>
          <div class="flex flex-wrap">
            <AvailablePlan
              v-for="plan in filteredPlans"
              :key="plan.id"
              :plan="plan"
              :country-code="countryCode"
            />
          </div>

          <div>
            <ul class="mt-6 flex max-w-xl flex-wrap">
              <li
                v-for="(feature, featureIndex) in [
                  'QR code menu',
                  'Tablet menu',
                  'Shareable link',
                  'Multiple languages',
                  'Design customization',
                  'Analytics',
                  'Feedback forms',
                  'Team management',
                  'Customer support',
                ]"
                :key="featureIndex"
                class="mr-4 mb-4 flex items-center text-sm font-medium text-gray-700 sm:mr-6"
              >
                <span
                  class="mr-1.5 block flex h-5 w-5 items-center justify-center rounded-full bg-lime-100 text-lime-700 sm:mr-2"
                >
                  <svg
                    class="h-3 w-3"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.00016 16.17L4.83016 12L3.41016 13.41L9.00016 19L21.0002 6.99997L19.5902 5.58997L9.00016 16.17Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                {{ feature }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import AvailablePlan from '@app/Shared/AvailablePlan.vue';
import ASelect from '@app/Shared/ASelect.vue';
import { Link } from '@inertiajs/vue3';
export default {
  components: {
    Link,
    ASelect,
    AvailablePlan,
    ACard,
    DashboardWrapper,
  },
  props: {
    plans: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    defaultCountryCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      countryCode: this.defaultCountryCode,
    };
  },
  computed: {
    filteredPlans() {
      return this.plans.filter((plan) => {
        return this.countryCode === 'AE'
          ? plan.currencyCode === 'AED'
          : plan.currencyCode !== 'AED';
      });
    },
    trialEndsAt() {
      return this.$page.props._restaurant.trialEndsAt
        ? this.$luxon.DateTime.fromISO(this.$page.props._restaurant.trialEndsAt)
        : null;
    },
    subscriptionCancelledAt() {
      return this.$page.props._restaurant.subscriptionCancelledAt
        ? this.$luxon.DateTime.fromISO(
            this.$page.props._restaurant.subscriptionCancelledAt,
          )
        : null;
    },
    isExpiredTrial() {
      return this.trialEndsAt && this.$luxon.DateTime.now() > this.trialEndsAt;
    },
  },
};
</script>
