<template>
  <AModal :max-width="650" @close="$emit('close')">
    <div class="mt-5">
      <form
        v-if="step === 'DOWNLOAD_SAMPLE'"
        @submit.prevent="step = 'UPLOAD_FILE'"
      >
        <ACardTitle class="text-center"> Step 1 of 3</ACardTitle>
        <div class="mt-10 flex flex-col justify-center">
          <div class="text-center">
            Download the sample Excel sheet below and fill it with your menu
            items.
          </div>
          <div class="mt-2 text-center text-sm text-gray-500">
            You can skip this step if you've already done it before.
          </div>
          <div class="mt-5 flex justify-center">
            <a :href="menuImportTemplateUrl" class="btn btn-outline-primary">
              <DownloadSimpleBold class="mr-1 inline h-5 w-5" />
              Download sample Excel sheet
            </a>
          </div>
        </div>

        <div class="mt-12 flex justify-between">
          <button
            type="button"
            class="btn btn-outline-secondary btn-sm"
            @click="$emit('close')"
          >
            Back
          </button>
          <button type="submit" class="btn btn-primary btn-sm">Next</button>
        </div>
      </form>

      <form v-if="step === 'UPLOAD_FILE'" @submit.prevent="parseFile">
        <ACardTitle class="text-center"> Step 2 of 3</ACardTitle>

        <div class="mt-8">
          <div class="text-center">
            Upload Excel sheet with filled menu items.
          </div>
          <div class="mt-2 mb-6 text-center text-sm text-gray-500">
            Make sure it is based on the
            <a :href="menuImportTemplateUrl" class="underline"
              >sample Excel sheet</a
            >.
          </div>

          <div v-if="file && !parsingFile">
            <div class="flex items-center">
              <button
                type="button"
                class="mr-2 inline-flex h-8 w-8 items-center justify-center rounded border border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                @click="file = null"
              >
                <TrashBold class="h-4 w-4" />
              </button>
              <div>
                {{ file.name }}
              </div>
            </div>
          </div>

          <div v-else class="h-[150px]">
            <FilePicker
              :loading="parsingFile"
              @file="
                (pickedFile) => {
                  file = pickedFile;
                  parseFile();
                }
              "
            >
              <template #description> Only .xlsx files supported </template>
            </FilePicker>
          </div>

          <div
            v-if="parsingError"
            class="mt-4 text-center text-sm font-semibold text-red-500"
          >
            {{ parsingError }}
          </div>
        </div>

        <div class="mt-12 flex justify-between">
          <button
            type="button"
            class="btn btn-outline-secondary btn-sm"
            :disabled="parsingFile"
            @click="step = 'DOWNLOAD_SAMPLE'"
          >
            Back
          </button>
          <button
            type="submit"
            class="btn btn-primary btn-sm"
            :disabled="!file || parsingFile"
            :data-loading="parsingFile"
          >
            Next
          </button>
        </div>
      </form>

      <form v-if="step === 'REVIEW_MENU'" @submit.prevent="importMenu">
        <ACardTitle class="text-center"> Step 3 of 3</ACardTitle>

        <div class="mt-6">
          <div class="text-center">Review your menu.</div>
          <div class="mx-auto mt-2 max-w-md text-center text-sm text-gray-500">
            You will be able to edit the menu at any time after it is imported.
          </div>
        </div>

        <div class="mt-10">
          <div v-if="languages.length > 1" class="mb-4">
            <div class="flex flex-wrap">
              <div
                v-for="language in languages"
                :key="language"
                class="mr-4 flex items-center"
              >
                <input
                  :id="`language_${language}`"
                  v-model="previewingLanguage"
                  type="radio"
                  :value="language"
                />
                <label :for="`language_${language}`" class="ml-1"
                  >Preview in {{ getLanguageName(language) }}</label
                >
              </div>
            </div>
          </div>

          <div>
            <div class="mb-2 text-sm text-gray-500">
              You can click on ▶ to expand categories and products.
            </div>

            <details
              v-for="(category, categoryIndex) in menu.categories"
              :key="categoryIndex"
            >
              <summary>
                {{ getTranslation(category.name) }}
              </summary>
              <div class="ml-4">
                <details
                  v-for="(product, productIndex) in category.products"
                  :key="productIndex"
                  class="pb-1"
                >
                  <summary>{{ getTranslation(product.name) }}</summary>
                  <div class="ml-4">
                    <img
                      v-if="product.image"
                      :src="getImageThumbnailUrl(product.image)"
                      class="mb-1 h-10 w-max rounded bg-gray-50"
                      alt=""
                      @error="onImgError(getImageThumbnailUrl(product.image))"
                    />
                    <p
                      v-if="product.ingredientWarnings.length > 0"
                      class="text-sm text-gray-500"
                    >
                      {{
                        product.ingredientWarnings
                          .map(({ title }) => title)
                          .join(', ')
                      }}
                    </p>
                    <p class="text-sm text-gray-500">
                      {{ getTranslation(product.description) }}
                    </p>
                    <p
                      v-for="(
                        productVariant, productVariantIndex
                      ) in product.productVariants"
                      :key="productVariantIndex"
                      class="text-sm font-medium text-gray-500"
                    >
                      <span v-if="productVariant.price">
                        {{ $page.props._restaurant.currencyCode }}
                        {{ productVariant.price }}
                      </span>

                      <span class="first:hidden last:hidden"> - </span>

                      <span v-if="getTranslation(productVariant.name)">{{
                        getTranslation(productVariant.name)
                      }}</span>
                    </p>
                  </div>
                </details>
              </div>
            </details>
          </div>
        </div>

        <div class="mt-6 max-w-sm text-xs italic text-gray-500">
          If you notice any mistakes, you can go back and upload a new Excel
          sheet or edit the menu after it is imported.
        </div>

        <div class="mt-6 flex justify-between">
          <button
            type="button"
            class="btn btn-outline-secondary btn-sm"
            @click="step = 'UPLOAD_FILE'"
          >
            Back
          </button>

          <button
            type="submit"
            class="btn btn-primary btn-sm"
            :disabled="importingMenu"
            :data-loading="importingMenu"
          >
            Import menu
          </button>
        </div>
      </form>
    </div>
  </AModal>
</template>
<script>
import AModal from '@app/Shared/AModal.vue';
import ACardTitle from '@app/Shared/ACardTitle.vue';
import axios from 'axios';
import { getLanguages } from '@/utils/language.js';
import FilePicker from '@app/Shared/FilePicker.vue';
import TrashBold from '@/phosphoricons/TrashBold.vue';
import DownloadSimpleBold from '@/phosphoricons/DownloadSimpleBold.vue';

export default {
  components: {
    DownloadSimpleBold,
    TrashBold,
    FilePicker,
    ACardTitle,
    AModal,
  },
  props: {
    branch: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      file: null,
      menuName: '',
      menu: null,
      previewingLanguage: this.$page.props._restaurant.languages[0],
      languages: this.$page.props._restaurant.languages,
      step: 'DOWNLOAD_SAMPLE',
      parsingFile: false,
      parsingError: null,
      importingMenu: false,
    };
  },
  computed: {
    menuImportTemplateUrl() {
      return '/menu-import-template';
    },
  },
  methods: {
    onImgError(imageThumbnailUrl) {
      throw new Error(
        `Failed to preview an image when importing the menu: ${imageThumbnailUrl}`,
      );
    },
    getImageThumbnailUrl(imageUrl) {
      if (!imageUrl) {
        return null;
      }

      return `https://images.weserv.nl/?${new URLSearchParams({
        url: imageUrl,
        w: 200,
      }).toString()}`;
    },
    getTranslation(translations) {
      return (
        translations[this.previewingLanguage] || translations[this.languages[0]]
      );
    },
    getLanguageName(language) {
      return getLanguages().find(({ code }) => code === language).name;
    },
    parseFile() {
      this.parsingError = null;
      this.parsingFile = true;

      const formData = new FormData();
      formData.append('file', this.file);

      axios
        .postForm('/ajax/parse-menu-file', formData)
        .then((response) => {
          this.menu = response.data.data.menu;
          this.step = 'REVIEW_MENU';
        })
        .catch((err) => {
          this.parsingError =
            err.response.data.message ||
            'There was an error processing your file. Please contact us.';
          this.file = null;
        })
        .finally(() => {
          this.parsingFile = false;
        });
    },
    importMenu() {
      this.importingMenu = true;

      this.$inertia.post(
        `/menus/${this.branch.id}/import`,
        {
          categories: this.menu.categories,
        },
        {
          onError: () => {
            this.$toast.error(
              'There was an error processing your import. Please contact us.',
            );
          },
          onFinish: () => {
            this.importingMenu = false;
          },
        },
      );
    },
  },
};
</script>
