<template>
  <DashboardWrapper title="Orders">
    <AAlert
      v-if="unableToEnableAudioNotifications"
      type="warning"
      class="mb-4"
      :actions="[
        {
          label: 'Enable audio notifications',
          callback: enableAudioNotifications,
        },
      ]"
    >
      <template #title> Audio notifications </template>
      <template #description>
        We were unable to automatically enable audio notifications. Please click
        the button below to enable them.
      </template>
    </AAlert>

    <ACard>
      <div>
        <strong>Menu with ordering: </strong>
        <a
          :href="$page.props._restaurant.orderMenuUrl"
          class="underline"
          target="_blank"
          >{{ $page.props._restaurant.orderMenuUrl }}</a
        >
      </div>
      <div class="mt-2 text-sm text-gray-500">
        You can share this link with your customers so they can order online
        from {{ $page.props._restaurant.name }}.
      </div>
    </ACard>
    <div class="mt-4 flex flex-wrap justify-between">
      <ATabs class="mr-4">
        <ATab
          href="/orders?tab=open"
          :current="
            getURL().pathname === '/orders' &&
            getURL().searchParams.get('tab') === 'open'
          "
        >
          Open
        </ATab>
        <ATab
          href="/orders?tab=closed"
          :current="
            getURL().pathname === '/orders' &&
            getURL().searchParams.get('tab') === 'closed'
          "
        >
          Closed
        </ATab>
      </ATabs>

      <div class="mb-4">
        <AButton
          variant="outline-primary"
          @click="$inertia.get('/notification-preferences')"
        >
          <template #icon><BellBold /></template>
          Notifications
        </AButton>
      </div>
    </div>
    <ACard v-if="orders.length === 0">No orders here...</ACard>
    <TransitionGroup name="flip-list">
      <OrderCard v-for="order in orders" :key="order.id" :order="order" />
    </TransitionGroup>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import ATabs from '@app/Shared/ATabs.vue';
import ATab from '@app/Shared/ATab.vue';
import axios from 'axios';
import OrderCard from '@app/Shared/OrderCard.vue';
import AAlert from '@app/Shared/AAlert.vue';
import AButton from '@app/Shared/AButton.vue';
import BellBold from '@/phosphoricons/BellBold.vue';

const orderNotificationAudio = new Audio('/audio/order-notification.mp3');
orderNotificationAudio.loop = true;
orderNotificationAudio.muted = true;

export default {
  components: {
    BellBold,
    AButton,
    AAlert,
    OrderCard,
    ATab,
    ATabs,
    ACard,
    DashboardWrapper,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    ordersState: {
      type: String,
      required: true,
    },
    hasNewOrders: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      unableToEnableAudioNotifications: false,
    };
  },
  watch: {
    hasNewOrders: {
      handler(hasNewOrders) {
        orderNotificationAudio.muted = !hasNewOrders;
      },
    },
  },
  mounted() {
    this.enableAudioNotifications();

    orderNotificationAudio.muted = !this.hasNewOrders;

    this.ordersStatePollingIntervalId = setInterval(() => {
      axios.post('/ajax/get-orders-state').then((res) => {
        if (res.data.data.ordersState !== this.ordersState) {
          this.$inertia.reload({ preserveScroll: true });
        }
      });
    }, 3000);
  },
  unmounted() {
    clearInterval(this.ordersStatePollingIntervalId);

    orderNotificationAudio.muted = true;
  },
  methods: {
    async enableAudioNotifications() {
      try {
        await orderNotificationAudio.play();

        this.unableToEnableAudioNotifications = false;
      } catch {
        if (this.unableToEnableAudioNotifications) {
          this.$toast.error('Unable to enable audio notifications');
        }

        this.unableToEnableAudioNotifications = true;
      }
    },
    getURL() {
      return new URL(location.href);
    },
  },
};
</script>

<style>
.flip-list-move {
  transition: transform 1s;
}
</style>
