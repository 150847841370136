<template>
  <AModal @close="$emit('close')">
    <form class="space-y-4" @submit.prevent="submit">
      <ACardTitle>Add new restaurant</ACardTitle>

      <div class="mt-2 text-gray-500">
        Each restaurant has its own QR code, menu items, design, users and
        subscription.
      </div>

      <AInput
        v-model="form.name"
        label="Restaurant name"
        :error="form.errors.name"
      />

      <div class="mt-3 flex space-x-2">
        <AButton type="submit" class="mt-3" :loading="form.processing">
          Add restaurant
        </AButton>
        <AButton variant="outline-secondary" @click="$emit('close')">
          Cancel
        </AButton>
      </div>
    </form>
  </AModal>
</template>
<script>
import AInput from '@app/Shared/AInput.vue';
import AButton from '@app/Shared/AButton.vue';
import { useForm } from '@inertiajs/vue3';
import AModal from '@app/Shared/AModal.vue';
import ACardTitle from '@app/Shared/ACardTitle.vue';

export default {
  components: {
    ACardTitle,
    AModal,
    AButton,
    AInput,
  },
  emits: ['close'],
  data() {
    return {
      form: useForm({
        name: '',
      }),
    };
  },
  methods: {
    async submit() {
      await this.form.post('/restaurants', {
        onSuccess: () => {
          this.$emit('close');
        },
        onError: () => {
          this.$toast.error('Please fix all highlighted fields');
        },
        preserveScroll: true,
      });
    },
  },
};
</script>
