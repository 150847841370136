<template>
  <div class="flex flex-wrap gap-x-2 gap-y-2">
    <InertiaLink
      class="btn btn-outline-secondary flex-1 whitespace-nowrap !text-gray-600"
      href="/login/google/redirect"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mr-2 h-4 w-4"
      >
        <path
          d="M23.745 12.27C23.745 11.48 23.675 10.73 23.555 10H12.255V14.51H18.725C18.435 15.99 17.585 17.24 16.325 18.09V21.09H20.185C22.445 19 23.745 15.92 23.745 12.27Z"
          fill="#4285F4"
        />
        <path
          d="M12.255 24C15.495 24 18.205 22.92 20.185 21.09L16.325 18.09C15.245 18.81 13.875 19.25 12.255 19.25C9.12504 19.25 6.47505 17.14 5.52505 14.29H1.54504V17.38C3.51504 21.3 7.56504 24 12.255 24Z"
          fill="#34A853"
        />
        <path
          d="M5.52501 14.2901C5.27501 13.5701 5.145 12.8001 5.145 12.0001C5.145 11.2001 5.28501 10.4301 5.52501 9.71012V6.62012H1.545C0.725004 8.24012 0.255005 10.0601 0.255005 12.0001C0.255005 13.9401 0.725004 15.7601 1.545 17.3801L5.52501 14.2901Z"
          fill="#FBBC05"
        />
        <path
          d="M12.255 4.75C14.025 4.75 15.605 5.36 16.855 6.55L20.275 3.13C18.205 1.19 15.495 0 12.255 0C7.56504 0 3.51504 2.7 1.54504 6.62L5.52505 9.71C6.47505 6.86 9.12504 4.75 12.255 4.75Z"
          fill="#EA4335"
        />
      </svg>

      {{ action }} with Google
    </InertiaLink>

    <InertiaLink
      class="btn btn-outline-secondary flex-1 whitespace-nowrap !text-gray-600"
      href="/login/microsoft/redirect"
    >
      <svg
        class="mr-2 h-4 w-4"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0156 5.08986V9.78361L16.674 10.8164C16.7177 10.829 16.8126 10.83 16.8564 10.8164L23.9938 6.0569C23.9938 5.49361 23.4625 5.08984 23.1627 5.08984L15.0156 5.08986Z"
          fill="#0072C6"
        />
        <path
          d="M15.0166 11.5337L16.53 12.5621C16.7433 12.7171 17.0002 12.5621 17.0002 12.5621C16.7441 12.7171 23.9956 7.95312 23.9956 7.95312V16.5799C23.9956 17.5189 23.3877 17.9129 22.7044 17.9129H15.0156L15.0166 11.5337Z"
          fill="#0072C6"
        />
        <path
          d="M7.18574 9.36914C6.66999 9.36914 6.25904 9.60883 5.95561 10.0874C5.65217 10.566 5.5 11.1996 5.5 11.9881C5.5 12.7885 5.65217 13.4212 5.95561 13.8862C6.25904 14.3521 6.65723 14.5838 7.14929 14.5838C7.65683 14.5838 8.05959 14.3576 8.35664 13.9051C8.65369 13.4527 8.80313 12.8245 8.80313 12.0216C8.80313 11.1843 8.65916 10.5327 8.3703 10.0668C8.08146 9.60163 7.68691 9.36914 7.18574 9.36914Z"
          fill="#0072C6"
        />
        <path
          d="M0 2.7057V21.0686L14.1238 23.9977V0.00195312L0 2.7057ZM9.45099 14.9916C8.8542 15.7684 8.07603 16.1578 7.11562 16.1578C6.17981 16.1578 5.41804 15.781 4.82849 15.0285C4.23985 14.275 3.94462 13.2945 3.94462 12.085C3.94462 10.8079 4.2435 9.77515 4.84215 8.98649C5.44082 8.19791 6.23357 7.80316 7.22041 7.80316C8.15258 7.80316 8.90706 8.17987 9.48205 8.93513C10.0579 9.69037 10.3459 10.6854 10.3459 11.9209C10.3467 13.1908 10.048 14.2146 9.45099 14.9916Z"
          fill="#0072C6"
        />
      </svg>

      {{ action }} with Outlook
    </InertiaLink>
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: true,
    },
  },
};
</script>
