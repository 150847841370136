<template>
  <DashboardWrapper title="QR code">
    <ACard class="mb-4">
      <div class="mb-4 flex justify-between">
        <div class="text-xl font-semibold">QR code</div>
      </div>
      <div class="mb-4 break-words">
        By scanning the QR code below, your guests can see the
        {{ $page.props._restaurant.name }} menu.
      </div>
      <AQRCode
        :value="orderingEnabled ? orderUrl : url"
        downloadable
        class="mb-4"
        colors-customizable
        :preferred-qr-color="$page.props._restaurant.primaryColor"
        :restaurant-name="$page.props._restaurant.name"
      >
        <template #additional-settings>
          <div>
            <ALabel class="flex items-center">
              <input v-model="orderingEnabled" type="checkbox" class="mr-1" />
              Allow orders using QR code
            </ALabel>
            <p class="mt-1 text-sm text-gray-500">
              <template v-if="orderingEnabled">
                This QR code opens a menu, where ordering is available.
              </template>
              <template v-else>
                This QR code opens a view-only menu, where ordering is not
                available.
              </template>
            </p>
          </div>
        </template>
      </AQRCode>
      <div class="mb-4">
        After downloading the QR code, you can print or engrave it.
      </div>
      <div>
        <template v-if="$page.props._restaurant.hasSubscription">
          If you update the menu, QR code stays the same.
        </template>
        <template v-else>
          If you update the menu or activate your subscription, QR code stays
          the same.
        </template>
      </div>
    </ACard>
    <ACard class="mb-4">
      <div class="mb-4 flex justify-between">
        <div class="text-xl font-semibold">Menu link</div>
      </div>
      <div class="mb-4">
        This link can be used to access the menu without scanning the QR code.
      </div>
      <div class="mb-4">
        <a :href="url" target="_blank" class="break-words underline">{{
          url
        }}</a>
      </div>
      <div>
        You can use it on your website, social networks, or send it to your
        guests.
      </div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import AQRCode from '@app/Shared/AQRCode.vue';
import ALabel from '@/app/Shared/ALabel.vue';

export default {
  components: { ALabel, AQRCode, ACard, DashboardWrapper },
  props: {
    url: {
      type: String,
      required: true,
    },
    orderUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      orderingEnabled: false,
    };
  },
  created() {
    if (this.canManageOrders) {
      this.orderingEnabled = true;
    }
  },
  computed: {
    canManageOrders() {
      return this.$page.props._restaurant.user.permissions.manageOrders;
    },
  },
};
</script>
