<template>
  <div class="mb-4 flex flex-wrap justify-between">
    <div class="mb-3 pr-3">
      <h2 class="text-2xl font-semibold"><slot /></h2>
      <div class="mt-1 text-sm text-gray-500 empty:hidden">
        <slot name="description" />
      </div>
    </div>

    <div class="flex flex-wrap space-x-2"><slot name="actions" /></div>
  </div>
</template>
