<template>
  <AModal no-bottom-padding-for-header @close="$emit('close')">
    <template #header>
      <ATranslationTabs v-model:selected-language-code="selectedLanguageCode" />
    </template>

    <div class="space-y-4">
      <AutoTranslateToggle
        v-model:auto-translate="form.autoTranslate"
        :selected-language-code="selectedLanguageCode"
      />

      <AInput
        v-model="form.name[selectedLanguageCode]"
        label="Product name"
        placeholder="Chocolate croissant"
        :error="form.errors[`name.${selectedLanguageCode}`]"
        :mandatory="isPrimaryLanguageSelected"
        :disabled="areTranslatableFieldsDisabled"
      />

      <div class="flex flex-col rounded border border-dashed p-3">
        <div class="mb-2 font-semibold">Product variations</div>
        <AError v-if="form.errors['variants']">
          {{ form.errors['variants'] }}
        </AError>
        <div
          v-for="(variant, variantIndex) in form.variants"
          :key="variantIndex"
          class="relative flex flex-col"
        >
          <div
            class="mb-3 grid grid-cols-6 gap-4 rounded border border-dashed py-2 px-3"
          >
            <div class="col-span-3">
              <div class="mb-2">
                <AInput
                  v-model="variant.name[selectedLanguageCode]"
                  label="Variation name"
                  :error="
                    form.errors[
                      `variants.${variantIndex}.name.${selectedLanguageCode}`
                    ]
                  "
                  placeholder="300 g"
                  :disabled="areTranslatableFieldsDisabled"
                />
              </div>
            </div>

            <div class="col-span-3">
              <AInput
                v-model="variant.price"
                type="number"
                :step="
                  1 /
                  Math.pow(10, $page.props._restaurant.currencyFractionDigits)
                "
                :placeholder="
                  $page.props._restaurant.currencyFractionDigits > 0
                    ? `39.${'0'.repeat($page.props._restaurant.currencyFractionDigits)}`
                    : '39'
                "
                :error="form.errors[`variants.${variantIndex}.price`]"
                label="Price"
                class="mr-4"
              />
            </div>
          </div>

          <div class="absolute -top-2 -right-2">
            <button
              v-if="form.variants.length > 1"
              v-tippy="{ content: 'Delete' }"
              type="button"
              class="flex h-8 w-8 items-center justify-center rounded-full border border-dashed border-gray-200 bg-white text-gray-700 hover:border-red-500 hover:text-red-500"
              @click="deleteVariant(variantIndex)"
            >
              <TrashBold class="h-4 w-4" />
            </button>
          </div>
        </div>
        <div class="flex">
          <AButton variant="outline-primary" @click="addVariant">
            + Add variation
          </AButton>
        </div>
      </div>

      <div v-if="$page.props.modifierSets.length > 0">
        <AMultiselect
          v-model="form.modifierSetIds"
          label="Modifier sets"
          :options="$page.props.modifierSets"
          :text-resolver="
            (ms) =>
              `${$getPrimaryTranslation(ms.name)} (${ms.modifiers.map((m) => $getPrimaryTranslation(m.name)).join(', ')})`
          "
          :value-resolver="(ms) => ms.id"
          :error="form.errors.modifierSetIds"
        />
      </div>

      <div>
        <ALabel for="product-image"> Product image </ALabel>

        <MediaFile
          id="product-image"
          class="mt-1"
          :file="form.image"
          :image-url="
            existingProduct && !form.deleteImage ? existingProduct.image : null
          "
          :allowed-extensions="['jpeg', 'jpg', 'png', 'webp']"
          :max-size-in-megabytes="50"
          @file="
            (file) => {
              form.image = file;
              form.deleteImage = false;
            }
          "
          @delete="
            () => {
              form.image = null;
              form.deleteImage = true;
            }
          "
        />

        <AError v-if="form.errors.image" class="mt-1">
          {{ form.errors.image }}
        </AError>
      </div>

      <div v-if="$page.props._restaurant.isVideoSupported">
        <ALabel for="product-video"> Product video </ALabel>

        <MediaFile
          id="product-video"
          class="mt-1"
          :file="form.video"
          :video-url="
            existingProduct && !form.deleteVideo ? existingProduct.video : null
          "
          :allowed-extensions="['mp4']"
          :max-size-in-megabytes="20"
          @file="
            (file) => {
              form.video = file;
              form.deleteVideo = false;
            }
          "
          @delete="
            () => {
              form.video = null;
              form.deleteVideo = true;
            }
          "
        />

        <AError v-if="form.errors.video" class="mt-1">
          {{ form.errors.video }}
        </AError>
      </div>

      <div v-if="$page.props._restaurant.is3dSupported">
        <ALabel for="product-scan"> Product 3D scan </ALabel>

        <MediaFile
          id="product-scan"
          class="mt-1"
          :file="form.scan"
          :file-url="
            existingProduct && !form.deleteScan ? existingProduct.scan : null
          "
          :allowed-extensions="['glb']"
          :max-size-in-megabytes="15"
          @file="
            (file) => {
              form.scan = file;
              form.deleteScan = false;
            }
          "
          @delete="
            () => {
              form.scan = null;
              form.deleteScan = true;
            }
          "
        />

        <AError v-if="form.errors.scan" class="mt-1">
          {{ form.errors.scan }}
        </AError>
      </div>

      <ASelect
        v-model="form.categoryId"
        label="Category"
        :options="categoryOptions"
        :value-resolver="(category) => category.id"
        :text-resolver="(category) => category.name"
        :error="form.errors.categoryId"
        empty-item-text="Select category"
        empty-item-disabled
        mandatory
      />

      <div>
        <AMultiselect
          v-model="form.ingredientWarningIds"
          label="Ingredient warnings"
          :options="$page.props.ingredientWarnings"
          :text-resolver="(warn) => warn.name"
          :value-resolver="(warn) => warn.id"
          :error="form.errors.ingredientWarningIds"
        />
      </div>

      <div>
        <ALabel> Badges </ALabel>
        <div class="flex flex-wrap">
          <div class="mr-3 flex items-center text-sm">
            <input v-model="form.isBestseller" class="mr-1" type="checkbox" />
            Bestseller
          </div>
          <div class="mr-3 flex items-center text-sm">
            <input v-model="form.isNew" class="mr-1" type="checkbox" /> New
          </div>
        </div>
      </div>

      <div>
        <ALabel class="mb-1">Description</ALabel>
        <HtmlEditor
          :key="`description-${selectedLanguageCode}`"
          v-model="form.description[selectedLanguageCode]"
          :disabled="areTranslatableFieldsDisabled"
        />
        <AError
          v-if="form.errors[`description.${selectedLanguageCode}`]"
          class="mt-1"
          >{{ form.errors[`description.${selectedLanguageCode}`] }}</AError
        >
      </div>
    </div>

    <template #footer>
      <div class="flex space-x-2">
        <AButton class="mt-3" :loading="form.processing" @click="submit">
          {{ existingProduct ? 'Save product' : 'Create product' }}
        </AButton>
        <AButton variant="outline-secondary" @click="$emit('close')">
          Cancel
        </AButton>
      </div>
    </template>
  </AModal>
</template>
<script>
import AInput from '@app/Shared/AInput.vue';
import AButton from '@app/Shared/AButton.vue';
import ASelect from '@app/Shared/ASelect.vue';
import { useForm } from '@inertiajs/vue3';
import AError from '@app/Shared/AError.vue';
import ALabel from '@app/Shared/ALabel.vue';
import AModal from '@app/Shared/AModal.vue';
import AMultiselect from '@app/Shared/AMultiselect.vue';
import HtmlEditor from '@app/Shared/HtmlEditor.vue';
import cloneDeep from 'lodash/cloneDeep';
import MediaFile from '@app/Shared/MediaFile.vue';
import HasTranslationTabs from '@app/Mixins/HasTranslationTabs.js';
import ATranslationTabs from '@app/Shared/ATranslationTabs.vue';
import AutoTranslateToggle from '@app/Shared/AutoTranslateToggle.vue';
import TrashBold from '@/phosphoricons/TrashBold.vue';

export default {
  components: {
    TrashBold,
    AutoTranslateToggle,
    ATranslationTabs,
    MediaFile,
    HtmlEditor,
    AMultiselect,
    AModal,
    ALabel,
    AError,
    ASelect,
    AButton,
    AInput,
  },
  mixins: [HasTranslationTabs],
  props: {
    existingProduct: {
      type: Object,
      default: null,
    },
    categories: {
      type: Array,
      required: true,
    },
    defaultCategoryId: {
      type: Number,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      form: useForm({
        autoTranslate: this.existingProduct
          ? cloneDeep(this.existingProduct.autoTranslate)
          : {},
        name: this.existingProduct ? cloneDeep(this.existingProduct.name) : {},
        description: this.existingProduct
          ? cloneDeep(this.existingProduct.description)
          : {},
        image: null,
        deleteImage: false,
        video: null,
        deleteVideo: false,
        scan: null,
        deleteScan: false,
        categoryId: this.existingProduct
          ? this.existingProduct.categoryId
          : this.defaultCategoryId,
        ingredientWarningIds: this.existingProduct
          ? cloneDeep(this.existingProduct.ingredientWarningIds)
          : [],
        modifierSetIds: this.existingProduct
          ? cloneDeep(this.existingProduct.modifierSetIds)
          : [],
        variants: this.existingProduct
          ? cloneDeep(this.existingProduct.variants)
          : [],
        isBestseller: this.existingProduct
          ? this.existingProduct.isBestseller
          : false,
        isNew: this.existingProduct ? this.existingProduct.isNew : false,
      }),
    };
  },
  computed: {
    autoTranslatePath() {
      return 'form.autoTranslate';
    },
    translatablePaths() {
      return [
        'form.name',
        'form.description',
        ...this.form.variants.map(
          (variant, variantIndex) => `form.variants.${variantIndex}.name`,
        ),
      ];
    },
    categoryOptions() {
      return this.getCategoryOptions();
    },
  },
  mounted() {
    if (this.form.variants.length === 0) {
      this.addVariant();
    }
  },
  methods: {
    addVariant() {
      this.form.variants.push({
        id: null,
        name: {}, // translatable
        price: null,
      });
    },
    deleteVariant(variantIndex) {
      this.form.variants.splice(variantIndex, 1);
      this.form.clearErrors(
        ...Object.keys(this.form.errors).filter((field) =>
          field.startsWith('variants'),
        ),
      );
    },
    getCategoryOptions() {
      const rows = [];

      for (const category of this.categories) {
        rows.push({
          id: category.id,
          name: `${this.$getPrimaryTranslation(category.name)}${
            category.isEnabled ? '' : ' (hidden)'
          }`,
        });
      }

      return rows;
    },
    async submit() {
      if (!this.existingProduct) {
        await this.form.post('/products', {
          onSuccess: () => {
            this.$emit('close');
            this.$toast.success('Saved');
          },
          onError: () => {
            this.$toast.error('Please fix all highlighted fields');
          },
          preserveScroll: true,
        });
      } else {
        await this.form.put(`/products/${this.existingProduct.id}`, {
          onSuccess: () => {
            this.$emit('close');
            this.$toast.success('Saved');
          },
          onError: () => {
            this.$toast.error('Please fix all highlighted fields');
          },
          preserveScroll: true,
        });
      }
    },
  },
};
</script>
