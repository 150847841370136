<template>
  <DashboardWrapper title="Notifications">
    <ACard class="mb-4">
      <div class="mb-6">
        <div>You can manage your notification preferences on this page.</div>
        <div class="mt-2 text-sm">
          <strong>Email</strong> - you will receive a notification to your email
        </div>
        <div v-if="0" class="text-sm">
          <strong>Push</strong> - you will receive a browser push notification
        </div>
      </div>
      <form @submit.prevent="save">
        <table>
          <thead class="bg-gray-50 text-left">
            <tr>
              <th class="border p-2">Type</th>
              <th class="border p-2">Email</th>
              <th v-if="0" class="border p-2">Push</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="notificationType in notificationTypes"
              :key="notificationType.type"
            >
              <td class="border p-2 capitalize">
                {{ notificationType.name }}
              </td>
              <td class="border p-2">
                <input
                  v-if="notificationType.isAvailableForEmail"
                  v-model="form.emailNotifications[notificationType.type]"
                  type="checkbox"
                />
                <div
                  v-else
                  v-tippy="{ content: 'Not supported yet' }"
                  class="w-max"
                >
                  <input
                    type="checkbox"
                    disabled
                    class="bg-gray-300 opacity-25"
                  />
                </div>
              </td>
              <td v-if="0" class="border p-2">
                <input
                  v-if="notificationType.isAvailableForPush"
                  v-model="form.pushNotifications[notificationType.type]"
                  type="checkbox"
                />
                <div
                  v-else
                  v-tippy="{ content: 'Not supported yet' }"
                  class="w-max"
                >
                  <input
                    type="checkbox"
                    disabled
                    class="bg-gray-300 opacity-25"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mt-4">
          <AButton type="submit" :loading="form.processing"> Save </AButton>
        </div>
      </form>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import { useForm } from '@inertiajs/vue3';
import AButton from '@app/Shared/AButton.vue';

export default {
  components: {
    AButton,
    ACard,
    DashboardWrapper,
  },
  props: {
    notificationTypes: {
      type: Array,
      required: true,
    },
    emailNotifications: {
      type: Object,
      required: true,
    },
    pushNotifications: {
      type: Object,
      required: true,
    },
    pushSubscriptionEndpoints: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        emailNotifications: { ...this.emailNotifications },
        pushNotifications: { ...this.pushNotifications },
      }),
    };
  },
  methods: {
    save() {
      this.form.put('/notification-preferences');
    },
  },
};
</script>
