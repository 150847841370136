<template>
  <DashboardWrapper title="Analytics">
    <AnalyticsTabs v-if="$page.props._restaurant.branches.length > 1" />

    <ACard class="mb-4">
      <div>
        <div class="flex flex-wrap">
          <div
            class="mr-3 mb-2 flex items-center rounded border border-gray-300 px-3 py-1"
          >
            <CalendarBlankRegular class="mr-2 h-4 w-4 text-gray-500" />
            {{ rangeStartFormatted }} -
            {{ rangeEndFormatted }}
          </div>

          <DatePicker v-model="range" is-range>
            <template #default="{ togglePopover }">
              <button
                type="button"
                class="flex items-center rounded bg-lime-500 px-3 py-1 text-white transition hover:bg-lime-600"
                @click="togglePopover"
              >
                Change range
              </button>
            </template>
          </DatePicker>
        </div>
        <div class="mt-1 text-sm text-gray-500">
          Timezone: {{ $page.props._user.timezone }}
        </div>
      </div>
    </ACard>
    <ACard class="mb-4">
      <div class="mb-4 flex justify-between">
        <ACardTitle>Menu Views</ACardTitle>
      </div>

      <div>
        Your menu has been viewed
        <span class="font-semibold"
          >{{ menuVisitsCount.toLocaleString() }}
          {{ menuVisitsCount === 1 ? 'time' : 'times' }}</span
        >
        between <span class="font-semibold">{{ rangeStartFormatted }}</span> and
        <span class="font-semibold">{{ rangeEndFormatted }}</span
        >.
      </div>
    </ACard>
    <ACard class="mb-4">
      <div class="mb-4 flex justify-between">
        <ACardTitle>Top Viewed Products</ACardTitle>
      </div>
      <div
        v-if="mostViewedProducts.length > 0"
        class="max-w-lg overflow-hidden rounded-lg border"
      >
        <div
          v-for="mostViewedProduct in mostViewedProducts"
          :key="mostViewedProduct.product.id"
          class="flex items-center justify-between px-3 py-2 odd:bg-gray-50"
        >
          <div class="flex items-center">
            <div class="mr-2">
              <img
                v-if="mostViewedProduct.product.image"
                class="h-6 w-6 rounded-full"
                :src="mostViewedProduct.product.image"
                alt=""
              />
            </div>
            <div>
              {{ $getPrimaryTranslation(mostViewedProduct.product.name) }}
            </div>
          </div>
          <div>
            {{ mostViewedProduct.views }}
          </div>
        </div>
      </div>
      <div v-else>None of your products have been viewed yet.</div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import ACardTitle from '@app/Shared/ACardTitle.vue';
import 'v-calendar/dist/style.css';
import { DatePicker } from 'v-calendar';
import { DateTime } from 'luxon';
import AnalyticsTabs from '@app/Shared/Tabs/AnalyticsTabs.vue';
import CalendarBlankRegular from '@/phosphoricons/CalendarBlankRegular.vue';

export default {
  components: {
    CalendarBlankRegular,
    AnalyticsTabs,
    ACardTitle,
    ACard,
    DashboardWrapper,
    DatePicker,
  },
  props: {
    menuVisitsCount: {
      type: Number,
      required: true,
    },
    mostViewedProducts: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    rangeStart: {
      type: String,
      required: true,
    },
    rangeEnd: {
      type: String,
      required: true,
    },
  },
  computed: {
    rangeStartFormatted() {
      return DateTime.fromISO(this.rangeStart).toLocaleString(
        DateTime.DATE_MED,
      );
    },
    rangeEndFormatted() {
      return DateTime.fromISO(this.rangeEnd).toLocaleString(DateTime.DATE_MED);
    },
    range: {
      get() {
        return {
          start: DateTime.fromISO(this.rangeStart).toJSDate(),
          end: DateTime.fromISO(this.rangeEnd).toJSDate(),
        };
      },
      set({ start, end }) {
        this.$inertia.reload({
          data: {
            filters: {
              ...this.filters,
              range_start: DateTime.fromJSDate(start).toISODate(),
              range_end: DateTime.fromJSDate(end).toISODate(),
            },
          },
        });
      },
    },
  },
};
</script>
