<template>
  <div
    class="relative my-4 mr-4 min-w-[230px] rounded-lg border border-gray-300/50 bg-white px-4 py-5 shadow-xl shadow-gray-300/20"
  >
    <span
      v-if="planName === 'Yearly'"
      class="absolute top-0 right-3 -translate-y-1/2 rounded-full border border-lime-500/50 bg-lime-50 px-3 py-0.5 text-sm font-semibold text-lime-700"
      >Save 15%</span
    >
    <span
      v-if="planName === 'Quarterly'"
      class="absolute top-0 right-3 -translate-y-1/2 rounded-full border border-lime-500/50 bg-lime-50 px-3 py-0.5 text-sm font-semibold text-lime-700"
      >Save 5%</span
    >
    <div class="text-md text-center font-semibold">{{ planName }} plan</div>
    <div class="mt-1 flex items-end justify-center">
      <div class="text-center text-3xl font-bold leading-none">
        {{ plan.currencyCode }}
        {{ Math.ceil(pricePerMonth) }}
      </div>
      <div class="mb-1 ml-1 text-xs leading-none text-gray-500">/ month</div>
    </div>
    <div class="mt-2 text-center text-xs text-gray-500">
      Charged {{ plan.currencyCode }} {{ plan.price }} every
      {{ everyPeriodName }}
    </div>

    <div class="mt-4">
      <button
        type="button"
        class="btn btn-primary btn-sm w-full"
        :disabled="loading"
        :data-loading="loading"
        @click="activate"
      >
        Subscribe
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    plan: {
      type: Object,
      required: true,
    },
    countryCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    planName() {
      if (this.plan.interval === 'year' && this.plan.intervalCount === 1) {
        return 'Yearly';
      }
      if (this.plan.interval === 'month' && this.plan.intervalCount === 3) {
        return 'Quarterly';
      }
      if (this.plan.interval === 'month' && this.plan.intervalCount === 1) {
        return 'Monthly';
      }
      return 'Custom';
    },
    pricePerMonth() {
      if (this.plan.interval === 'year') {
        return this.plan.price / 12 / this.plan.intervalCount;
      }
      if (this.plan.interval === 'month') {
        return this.plan.price / this.plan.intervalCount;
      }
      return null;
    },
    everyPeriodName() {
      if (this.plan.interval === 'year' && this.plan.intervalCount === 1) {
        return 'year';
      }
      if (this.plan.interval === 'month' && this.plan.intervalCount === 3) {
        return 'quarter';
      }
      if (this.plan.interval === 'month' && this.plan.intervalCount === 1) {
        return 'month';
      }
      return `${this.plan.intervalCount} ${this.plan.interval}s`;
    },
  },
  methods: {
    activate() {
      this.loading = true;
      window.fbq && window.fbq('track', 'InitiateCheckout');
      this.$inertia.post('/billing/checkout', {
        plan_id: this.plan.id,
        country_code: this.countryCode,
      });
      setTimeout(() => {
        this.loading = false;
      }, 5000);
    },
  },
};
</script>
