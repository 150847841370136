<template>
  <AModal no-bottom-padding-for-header @close="$emit('close')">
    <template #header>
      <ATranslationTabs v-model:selected-language-code="selectedLanguageCode" />
    </template>

    <div>
      <div v-if="isFirstCategory" class="mb-6">
        <div class="mr-6 text-xl font-bold">
          Let's create your first category
        </div>
        <div class="mt-2 text-sm text-gray-500">
          Once created, you can add products to it.
        </div>
      </div>

      <div class="space-y-4">
        <AutoTranslateToggle
          v-model:auto-translate="form.autoTranslate"
          :selected-language-code="selectedLanguageCode"
        />

        <AInput
          v-model="form.name[selectedLanguageCode]"
          label="Category name"
          placeholder="Appetizers"
          :error="form.errors[`name.${selectedLanguageCode}`]"
          :mandatory="isPrimaryLanguageSelected"
          :disabled="areTranslatableFieldsDisabled"
        />

        <AInput
          v-if="!isFirstCategory"
          v-model="form.description[selectedLanguageCode]"
          label="Category description"
          placeholder=""
          :error="form.errors[`description.${selectedLanguageCode}`]"
          type="textarea"
          :rows="3"
          :disabled="areTranslatableFieldsDisabled"
        />

        <ASelect
          v-if="branch.isCategoryTypesNavbarEnabled"
          v-model="form.type"
          label="Type"
          :options="types"
          :value-resolver="(option) => option.type"
          :text-resolver="(option) => option.label"
        />

        <div v-if="!isFirstCategory">
          <ALabel for="category-image"> Category image </ALabel>

          <MediaFile
            id="category-image"
            class="mt-1"
            :file="form.image"
            :image-url="
              existingCategory && !form.deleteImage
                ? existingCategory.image
                : null
            "
            :allowed-extensions="['jpeg', 'jpg', 'png', 'webp']"
            :max-size-in-megabytes="15"
            :preview-aspect-ratio="224 / 96"
            @file="
              (file) => {
                form.image = file;
                form.deleteImage = false;
              }
            "
            @delete="
              () => {
                form.image = null;
                form.deleteImage = true;
              }
            "
          />

          <div class="mt-1 text-xs text-gray-500">
            Recommended resolution: 672x288px
          </div>

          <div class="mt-1 text-xs text-gray-500">
            It is displayed in the left sidebar. If missing, the image of first
            product is used.
          </div>

          <AError v-if="form.errors.image" class="mt-1">
            {{ form.errors.image }}
          </AError>
        </div>

        <ASelect
          v-if="existingCategory && $page.props._restaurant.branches.length > 1"
          v-model="form.branchId"
          label="Menu"
          :options="$page.props._restaurant.branches"
          :value-resolver="(option) => option.id"
          :text-resolver="(option) => $getPrimaryTranslation(option.name)"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex space-x-2">
        <AButton class="mt-3" :loading="form.processing" @click="submit">
          {{ existingCategory ? 'Save category' : 'Create category' }}
        </AButton>
        <AButton variant="outline-secondary" @click="$emit('close')">
          Cancel
        </AButton>
      </div>
    </template>
  </AModal>
</template>
<script>
import AInput from '@app/Shared/AInput.vue';
import AButton from '@app/Shared/AButton.vue';
import ASelect from '@app/Shared/ASelect.vue';
import { useForm } from '@inertiajs/vue3';
import AError from '@app/Shared/AError.vue';
import ALabel from '@app/Shared/ALabel.vue';
import AModal from '@app/Shared/AModal.vue';
import MediaFile from '@app/Shared/MediaFile.vue';
import ATranslationTabs from '@app/Shared/ATranslationTabs.vue';
import HasTranslationTabs from '@app/Mixins/HasTranslationTabs.js';
import cloneDeep from 'lodash/cloneDeep.js';
import AutoTranslateToggle from '@app/Shared/AutoTranslateToggle.vue';

export default {
  components: {
    AutoTranslateToggle,
    ATranslationTabs,
    MediaFile,
    AModal,
    ALabel,
    AError,
    ASelect,
    AButton,
    AInput,
  },
  mixins: [HasTranslationTabs],
  props: {
    existingCategory: {
      type: Object,
      default: null,
    },
    branch: {
      type: Object,
      required: true,
    },
    isFirstCategory: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      form: useForm({
        autoTranslate: this.existingCategory
          ? cloneDeep(this.existingCategory.autoTranslate)
          : {},
        name: this.existingCategory ? { ...this.existingCategory.name } : {},
        description: this.existingCategory
          ? { ...this.existingCategory.description }
          : {},
        branchId: this.branch.id,
        // todo
        type: this.existingCategory ? this.existingCategory.type : 'food',
        image: null,
        deleteImage: false,
      }),
      // todo
      types: [
        { type: 'food', label: 'Food' },
        { type: 'drinks', label: 'Drinks' },
        { type: 'shisha', label: 'Shisha' },
        { type: 'desserts', label: 'Desserts' },
        { type: 'offers', label: 'Offers' },
      ],
    };
  },
  computed: {
    autoTranslatePath() {
      return 'form.autoTranslate';
    },
    translatablePaths() {
      return ['form.name', 'form.description'];
    },
  },
  methods: {
    async submit() {
      if (!this.existingCategory) {
        await this.form.post('/categories', {
          onSuccess: () => {
            this.$emit('close');
            this.$toast.success('Saved');
          },
          onError: () => {
            this.$toast.error('Please fix all highlighted fields');
          },
          preserveScroll: true,
        });
      } else {
        await this.form.put(`/categories/${this.existingCategory.id}`, {
          onSuccess: () => {
            this.$emit('close');
            this.$toast.success('Saved');
          },
          onError: () => {
            this.$toast.error('Please fix all highlighted fields');
          },
          preserveScroll: true,
        });
      }
    },
  },
};
</script>
